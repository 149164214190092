import axios from 'axios';
import config from "../lib/config.js";


export const loginAdmin = async(payload)=>{
    console.log("admin login ",payload,config.AdminAPI)
    let data = {"data":payload}
    try{
        const resp = await axios({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data':data
            
        })
        console.log("returned response",resp.data)
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("err",err)
    }


}


export const check  = async()=>{

    try{
    var data = {"test":"testdata"}
    var resp = await axios({
        "method":"POST",
        'url':`${config.AdminAPI}/checkroute`,
        "data":data,
        "headers":{
            "Authorization":localStorage.getItem("token")
        }
    })

    }
    catch(err){console.log("err".err)}

}

export const youtubeurlsave=async(url)=>{

    //    console.log("dataaa",url)
       var urldata={'Url' : url }
       console.log("dataaa",url,urldata)
            try{
                var resp = await axios({
                    "method":"post",
                    "url":`${config.AdminAPI}/youtubeurlsave`,
                    "data":urldata
                })
                return resp.data  
            }
            catch(err){
               throw err
            }
        }

export const Urlsave=async(url)=>{

    //    console.log("dataaa",url)
       var urldata={'Url' : url }
       console.log("dataaa",url,urldata)
            try{
                var resp = await axios({
                    "method":"post",
                    "url":`${config.AdminAPI}/urlsave`,
                    "data":urldata
                })
                return resp.data  
            }
            catch(err){
               throw err
            }
        }