import { lazy } from "react";



const Routes = [
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/addoredit",
        component: lazy(() => import('../Youtube link/youtubelink.js')),
        name: '/'
    },
    
    {
        path: "/addImage",
        component: lazy(() => import('../images/addImages')),
        name: '/'
    },
    {
        path: "/editImage",
        component: lazy(() => import('../images/editimage.js')),
        name: '/'
    },
    {
        path: "/images",
        component: lazy(() => import('../images/imageList.js')),
        name: '/'
    },
    {
        path: "/youtube",
        component: lazy(() => import('../Youtube link/list.js')),
        name: '/'
    },
    {
        path: "/event",
        component: lazy(() => import('../event/listevent')),
        name: '/admin'
    },{
        path: "/addevent",
        component: lazy(() => import('../event/addevent')),
        name: '/admin'
    },{
        path: "/editevent",
        component: lazy(() => import('../event/editevent')),
        name: '/admin'
    },
    {
        path: "/dashboard",
        component: lazy(() => import('../dashboard/Dashboard.js')),
        name: '/admin'
    },
    {
        path: "/homecms",
        component: lazy(() => import('../cmsAndFaq/homecmsbox.js')),
        name: '/admin'
    },
    {
        path: "/footercms",
        component: lazy(() => import('../cmsAndFaq/footercms.js')),
        name: '/admin'
    },
    {
        path: "/communitycms",
        component: lazy(() => import('../cmsAndFaq/communitycms.js')),
        name: '/admin'
    },
    {
        path: "/provinancecms",
        component: lazy(() => import('../cmsAndFaq/provinancecms.js')),
        name: '/admin'
    },
    {
        path: "/community",
        component: lazy(() => import('../community/communitylist.js')),
        name: '/admin'
    },
    {
        path: "/message",
        component: lazy(() => import('../messages/msglist.js')),
        name: '/admin'
    },
    {
        path: "/editarticle",
        component: lazy(() => import('../article/editarticle.js')),
        name: '/admin'
    },
    {
        path: "/article",
        component: lazy(() => import('../article/article.js')),
        name: '/admin'
    },
    {
        path: "/addarticle",
        component: lazy(() => import('../article/addarticle.js')),
        name: '/admin'
    },
    {
        path: "/nftlist",
        component: lazy(() => import('../nftlist/nftList.js')),
        name: '/admin'
    },
    {
        path: "/url",
        component: lazy(() => import('../../app/url/url.js')),
        name: '/admin'
    },
    {
        path: "/actioncms",
        component: lazy(() => import('../cmsAndFaq/actionplancms')),
        name: '/admin'
    },
    {
        path: "/benifitcms",
        component: lazy(() => import('../cmsAndFaq/benifitscms')),
        name: '/admin'
    },
    {
        path: "/editcms",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/cmslist",
        component: lazy(() => import('../cmscontent/cmslist.js')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../cmsAndFaq/editcms.js')),
        name: '/admin'
    },
    // {
    //     path: "/cmsandfaq",
    //     component: lazy(() => import('../cmsAndFaq/cmsbox.js')),
    //     name: '/admin'
    // },
    {
        path: "/subscriberslist",
        component: lazy(() => import('../subscribers/subscriberlist.js')),
        name: '/admin'
    },
    {
        path: "/sendmail",
        component: lazy(() => import('../subscribers/sendmail.js')),
        name: '/admin'
    },
    {
        path: "/sociallist",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/addsocial",
        component: lazy(() => import('../sociallinks/addsocial.js')),
        name: '/admin'
    },
    {
        path: "/editsociallink",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/faqlist",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq.js')),
        name: '/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/emailtemplatelist",
        component: lazy(()=> import('../emailtemplates/emailtemplatelist')),
        name:'/admin'
    },
    {
        path : "/emailtemplateedit",
        component : lazy(()=> import('../emailtemplates/emailtemplateedit')),
        name:'/admin'
    }
]


export default Routes;
