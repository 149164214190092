let key = {};
let IPFS_IMG = "https://freakyfeko.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "production";
var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:8080/v1/admin";
  Front_market_Url = "http://localhost:3000/Ditmax";
  var image_url = "hhttp://localhost:3331/token";
  ImG = "http://localhost:8080";
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://localhost:3331/v1";
}

if (EnvName === "demo") {
  Back_Url = "http://192.53.121.26:8902/v1/admin";
  Front_market_Url = "http://nftdemo.bimaticz.com/fekoadmin";
  
  var image_url = "http://192.53.121.26:2525/token";
  ImG = "http://192.53.121.26:8902";
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "97";
  var chainId = "0x61";
  BNBProvider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  var Back_Url_Token = "http://192.53.121.26:8902/v1";
}

if (EnvName === "production") {
  Back_Url = "https://api.freakyfeko.com/v1/admin";
  Front_market_Url = "https://www.freakyfeko.com";

  var image_url = "https://api.freakyfeko.com/token";
  ImG = "https://api.freakyfeko.com/";
  var tradeAddress = "0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf";
  var singleAddress = "0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383";
  var multipeAddress = "0x484496568071F25678C07B372eBd6Ac54Bc18aB2";
  var networkVersion = "1";
  var chainId = "0x1";
  BNBProvider = "https://mainnet.infura.io/v3/d24d2226fb4b4627b59ca617464d834d";
  var Back_Url_Token = "https://api.freakyfeko.com/v1";
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBProvider,
  tradeAddress: tradeAddress,
  singleAddress: singleAddress,
  multipeAddress: multipeAddress,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  AdminAddress: "0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion
};

export default key;
